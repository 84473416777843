import * as React from "react"
import styled from "styled-components"

const WYSTAW_FAKTURE_URL = "https://programista.fakturuj.to/wystaw-fakture/"

const WystawFaktureIframe = styled.iframe`
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100vw;
`

const WystawFakturePage = () => (
  <WystawFaktureIframe src={WYSTAW_FAKTURE_URL} />
)

export default WystawFakturePage
